<template>
  <el-tag class="pos-tag-component" v-bind="$attrs" v-on="$listeners" :effect="$attrs.effect || 'plain'">
    <slot></slot>
  </el-tag>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.pos-tag-component {
  position: absolute;
  transform: rotate(-30deg) translateY(-50%);
  top: 50%;
  right: 5px;
  font-size: 14px;
  border: 1px dashed;
  height: auto;
  line-height: 1;
  padding: 4px;
  border-radius: 0;
  background-color: #fff !important;
}
</style>
